<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Header: Personal Info -->
        <div class="d-flex my-2">
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Personal Information
          </h4>
        </div>

        <validation-observer ref="infoUserForm">
          <!-- Form: Personal Info Form -->
          <b-form class="mt-1">
            <b-row>
              <!-- Field: Title -->
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Title"
                  label-for="title"
                  label-class="mb-1 mr-2"
                >
                  <validation-provider
                    name="title"
                    vid="title"
                  >
                    <b-form-radio-group
                      id="title"
                      v-model="title"
                      :options="titleOptions"
                      value="Mr"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Field: First Name -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="First Name"
                  label-for="first-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="firstName"
                    vid="firstName"
                    rules="required"
                  >
                    <b-form-input
                      id="first-name"
                      v-model="firstName"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Middle Name -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Middle Name (Optional)"
                  label-for="middle-name"
                >
                  <b-form-input
                    id="middle-name"
                    v-model="middleName"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Last Name -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Last Name"
                  label-for="last-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="lastName"
                    vid="lastName"
                    rules="required"
                  >
                    <b-form-input
                      id="last-name"
                      v-model="lastName"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Birth Date -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Birth Date"
                  label-for="birth-date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="dob"
                    vid="dob"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="dob"
                      class="form-control"
                      :config="configDate"
                      placeholder="DD-MM-YYYY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- phone number -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Phone Number"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phoneNumber"
                    vid="phoneNumber"
                    rules="required|phone"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="phoneNumber"
                        type="tel"
                        maxlength="10"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: email -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Header: Personal Info -->
            <div class="d-flex mt-2">
              <feather-icon
                icon="MapPinIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Address
              </h4>
            </div>

            <b-row class="mt-1">
              <!-- Address -->
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="fullAddress"
                  vid="fullAddress"
                >
                  <vue-google-autocomplete
                    id="map"
                    ref="fullAddress"
                    v-model="fullAddress"
                    class="form-control"
                    placeholder="Full Address"
                    country="aus"
                    :fields="fields"
                    @placechanged="getAddressData"
                    @inputChange="changeInput"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <div class="d-flex my-2">
              <feather-icon
                icon="UserIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                Account
              </h4>
            </div>
            <b-row>
              <!-- Field: password -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Password"
                  label-for="password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    vid="password"
                    rules="required|min:6|password"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        v-model="valuePassword"
                        :type="userPassword"
                        name="password"
                        placeholder="Enter Password"
                        autocomplete="new-password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePassword"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col class="d-flex justify-content-end">
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0"
                  :disabled="loadingCreateAccount"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click.prevent="handleSubmitForm"
                >
                  <Spinner v-if="loadingCreateAccount" />
                  <template v-else>
                    Create account
                  </template>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { mapState, mapActions } from 'vuex'
import Spinner from '@core/components/Spinner'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, password, min, phone,
} from '@validations'
import useToastHandler from '@/services/toastHandler'

import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { calculateDate18YearsAgo } from '@core/utils/enum/date'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    BFormRadioGroup,
    BButton,
    Spinner,
    ValidationProvider,
    ValidationObserver,
    // Cleave,
    BInputGroup,
    BInputGroupAppend,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      required,
      email,
      password,
      phone,
      min,
      title: 'Mr',
      firstName: '',
      middleName: '',
      lastName: '',
      phoneNumber: '',
      dob: '',
      valuePassword: '',
      userPassword: 'password',
      fullAddress: null,
      suburb: '',
      state: '',
      postCode: '',
      countryCode: 'AUS',
      streetName: '',
      streetNumber: '',
      streetType: '',
      fields: [
        'address_components',
        'adr_address',
        'alt_id',
        'formatted_address',
        'geometry',
        'id',
        'name',
        'scope',
        'type',
        'vicinity',
      ],
      configDate: {
        altFormat: 'd-m-Y',
        altInput: true,
        maxDate: this.calculateDate18YearsAgo(),
      },
    }
  },

  computed: {
    ...mapState({
      ...mapState('user/detail', ['loadingCreateAccount']),
    }),
    passwordToggleIcon() {
      return this.userPassword === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions({
      asyncCreateAccount: 'user/detail/createAccount',
    }),

    getAddressData(addressData, place) {
      this.fullAddress = place.formatted_address
      const routeItem = place.address_components.find(item => item.types.includes('route'))

      const streetLongName = routeItem?.long_name.split(' ')
      this.streetType = streetLongName?.pop() || ''
      this.streetName = streetLongName.join(' ') || ''
      const postCodeItem = place.address_components.find(item => item.types.includes('postal_code'))
      this.postCode = postCodeItem?.long_name || ''
      const streetNumberItem = place.address_components.find(item => item.types.includes('street_number'))
      this.streetNumber = streetNumberItem?.long_name || ''
      const subpremiseItem = place.address_components.find(item => item.types.includes('subpremise'))
      this.unitNumber = subpremiseItem?.long_name || ''
      const stateItem = place.address_components.find(item => item.types.includes('administrative_area_level_1'))
      this.state = stateItem?.long_name || ''
      const cityItem = place.address_components.find(item => item.types.includes('locality'))
      this.suburb = cityItem?.long_name || ''
    },
    togglePassword() {
      this.userPassword = this.userPassword === 'password' ? 'text' : 'password'
    },
    changeInput(e) {
      if (e.newVal === '') this.fullAddress = null
    },
    async handleSubmitForm() {
      const valid = await this.$refs.infoUserForm.validate()
      if (!valid || !this.$refs.infoUserForm.flags.changed) return
      const data = {
        phoneNumber: `+61${this.phoneNumber?.slice(1, 10)}`,
        firstName: this.firstName,
        ...(this.middleName && { middleName: this.middleName }),
        lastName: this.lastName,
        dob: this.dob,
        title: this.title,
        gender: this.title === 'Mr' ? 'M' : 'F',
        email: this.email,
        suburb: this.suburb,
        state: this.state,
        postCode: this.postCode,
        country: this.countryCode,
        password: this.valuePassword,
        streetName: this.streetName,
        streetNumber: this.streetNumber,
        streetType: this.streetType,
        fullAddress: this.fullAddress,
      }
      const result = await this.asyncCreateAccount(data)
      if (!result) return
      this.showToastSuccess('Create Success', 'Account created successfully')
      this.$router.push({ name: 'users-list' })
    },
    handleCheckKey(key) {
      return this.key && { [key]: this.key }
    },
  },
  setup() {
    const titleOptions = [
      { text: 'Mr', value: 'Mr' },
      { text: 'Ms', value: 'Ms' },
      { text: 'Mrs', value: 'Mrs' },
      { text: 'Miss', value: 'Miss' },
      { text: 'Dr', value: 'Dr' },
    ]

    const { showToastSuccess } = useToastHandler()

    return {
      titleOptions,
      showToastSuccess,
      calculateDate18YearsAgo,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.dark-layout
  .flatpickr-calendar
  .flatpickr-days
  .flatpickr-day:hover:not(.selected):not(.today):not(.startRange):not(.endRange) {
  background: #ccc;
}
</style>
